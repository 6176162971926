import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ad"]
  
  connect() {
    console.log('Ad controller connected')

    if (!this.adLoaded) {
      this.adLoaded = false
      
      this.initializeAd()
      this.setupIntersectionObserver()
      
      // Safety check for adblockers or unfilled ads
      setTimeout(() => {
        if (this.hasAdTarget && this.isAdEmpty(this.adTarget)) {
          console.log('Safety check: ad is empty')
          this.handleEmptyAd()
        }
      }, 1000)
    }
  }
  
  initializeAd() {
    console.log('Initializing ad')

    const checkAdStatus = () => {
      if (this.hasAdTarget) {
        const status = this.adTarget.getAttribute('data-adsbygoogle-status')
        console.log('Checking ad status:', status)
        if (status === 'done') {
          this.handleAdLoaded()
        } else if (status === 'unfilled') {
          this.handleEmptyAd()
        }
      }
    }

    // Set up observer for changes
    this.adObserver = new MutationObserver((mutations) => {
      const status = this.adTarget.getAttribute('data-adsbygoogle-status')
      console.log('Mutation observed, status:', status)
      if (status === 'done') {
        this.handleAdLoaded()
      } else if (status === 'unfilled') {
        this.handleEmptyAd()
      }
    })
    
    if (this.hasAdTarget) {
      this.adObserver.observe(this.adTarget, {
        attributes: true,
        attributeFilter: ['data-adsbygoogle-status']
      })
    }
  }
  
  setupIntersectionObserver() {
    const options = {
      root: null,
      rootMargin: '50px',
      threshold: 0
    }
    
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !this.adLoaded) {
          console.log('Ad container intersecting, loading ad')
          this.loadAd()
          this.intersectionObserver.unobserve(entry.target)
        }
      })
    }, options)
    
    if (this.hasAdTarget) {
      this.intersectionObserver.observe(this.adTarget)
    }
  }
  
  loadAd() {
    console.log('Loading ad')
    const script = document.createElement('script')
    script.innerHTML = '(adsbygoogle = window.adsbygoogle || []).push({});'
    this.element.appendChild(script)
  }
  
  isAdEmpty(element) {
    const status = element.getAttribute('data-adsbygoogle-status')
    console.log('Checking if ad is empty, status:', status)
    return status === 'unfilled' || status === null
  }
  
  handleAdLoaded() {
    if (this.adTarget.getAttribute('data-adsbygoogle-status') === 'done') {
      console.log('Ad loaded successfully')
      this.adLoaded = true
      this.element.classList.remove('ad-empty')
    }
  }
  
  handleEmptyAd() {
    console.log('Ad is empty or failed to load')
    this.adLoaded = false
    this.element.classList.add('ad-empty')
  }
  
  disconnect() {
    if (this.adObserver) {
      this.adObserver.disconnect()
    }
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }
}